<template>
  <div class="account-container">
    <div class="welcome-message">
          Вітаємо, {{ user.first_name }}!
        </div>
    <div class="profile">
      
      <!-- Ліва колонка з меню -->
      <div class="menu-column">
        <nav>
          <ul class='navigation'>
            <li @click="currentSection = 'profile'" :class="{ active: currentSection === 'profile' }">
              <img :src="profileIcon" alt="profile icon" class="menu-icon" />
              <span class="menu-text">{{ user.email }}</span>
            </li>
            <li @click="currentSection = 'orders'" :class="{ active: currentSection === 'orders' }">
              <img :src="ordersIcon" alt="orders icon" class="menu-icon" />
              <span class="menu-text">Мої замовлення</span>
            </li>
            <li @click="currentSection = 'wishlist'" :class="{ active: currentSection === 'wishlist' }">
                <img :src="wishlistIcon" alt="wishlist icon" class="menu-icon" />
                <span class="menu-text">Список бажань</span>
              </li>
            <li @click="handleLogout" class="logout-button">
              <img :src="logoutIcon" alt="logout icon" class="menu-icon" />
              <span class="menu-text">Вийти</span>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Права колонка з контентом -->
      <section class="content">
       
      
        <!-- Відображаємо відповідну секцію на основі вибору в меню -->
        <div v-if="currentSection === 'profile'" class="profile-section">
          <div class="header">
            <h2>Особисті дані</h2>
            <button @click="saveUserData" class="edit-button">Зберегти</button>
          </div>
          <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>

          <div class="profile-info-grid">
            <div class="profile-info-item">
              <span class="label">Ім'я:</span>
              <input v-model="user.first_name" class="value" />
            </div>
            <div class="profile-info-item">
              <span class="label">Прізвище:</span>
              <input v-model="user.last_name" class="value" />
            </div>
          </div>
          <div class="profile-info-item">
              <span class="label">Номер телефону:</span>
              <div class="phone-wrapper">
                <span class="prefix">+38</span>
                <input v-model="phoneNumber" @input="formatPhoneNumber" class="value" maxlength="13" placeholder="___-___-__-__" />
              </div>
              <span v-if="errors.phone" class="error-message">Невірний номер телефону</span>
            </div>
         

        </div>

        <!-- Замовлення -->
        <div v-else-if="currentSection === 'orders'" class="orders-section">
          <h2>Мої замовлення</h2>
          <div v-if="orders.length > 0">
            <div class="order" v-for="(order, index) in orders" :key="order.id">
              <div 
                class="order-summary"
                @click="toggleOrderDetails(index)" 
              >
              <span class="order-number">Замовлення #{{ order.id }}</span>

                <!-- Статус оплати з кольоровим кружечком -->
                <span class="order-status">
                  Оплата:
                  <span :class="{'status-circle green': order.payed, 'status-circle red': !order.payed}"></span>
                </span>

                <span class="order-total">Сума: {{ order.total_price }} грн</span>

                <!-- Форматована дата -->
                <span class="order-time">Час замовлення: {{ formatDate(order.created) }}</span>
              </div>

              <div v-if="expandedOrder === index" class="order-products">
                <h3>Продукти у замовленні:</h3>
                <ul>
                  <li v-for="product in order.order_products" :key="product.variant">
                    {{ product.name }} - {{ product.quantity }} шт. за {{ product.price }} грн
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>
            <p>У вас ще немає замовлень.</p>
          </div>
        </div>
        <!-- Відображення списку бажань -->
          <div v-else-if="currentSection === 'wishlist'" class="wishlist-section">
            <h2>Список бажань</h2>
            <div v-if="wishlistItems.length > 0">
              <div class="wish_list_item" v-for="item in wishlistItems" :key="item.id">
                <div class="wish_list_img">
                  <img :src="getImageUrl(item.image)" :alt="item.product_name" />
                </div>
                <div class="wish_list_action">
                  <div class="wist_list_title">
                    <a>{{ item.product_name }}</a>
                    <button @click="removeFromWishList(item.id)" class="wist_list_title_bnt">
                      <svg data-v-3b25bc3e="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line data-v-3b25bc3e="" x1="18" y1="6" x2="6" y2="18"></line><line data-v-3b25bc3e="" x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                  </div>
                  <div class="wish_list_price">
                    <p :class="{ 'crossed-price': item.discount_price > 0 }">
                      {{ item.price }}₴
                    </p>
                    <p v-if="item.discount_price > 0" class="disc_price">
                      {{ item.discount_price }}₴
                    </p>
                    <button @click="addProdToCart(item.id, 1)">Купити</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="empty-wishlist-message">
              <p>Ваш список бажань порожній</p>
            </div>
          </div>

      </section>
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import profileIcon from '@/assets/img/ico/account/my_account.svg'; // Імпорт іконки профілю
import ordersIcon from '@/assets/img/ico/account/my_backet.svg'; // Імпорт іконки замовлень
import logoutIcon from '@/assets/img/ico/account/logout.svg'; // Імпорт іконки для виходу
import instance from '@/api/axiosInstance'; // Використовуємо axios для API запитів
import Cookies from 'js-cookie'; // Використовуємо куки для аутентифікації
import wishlistIcon from '@/assets/img/ico/wishlist.svg';



export default {
  name: 'AccountPage',
  data() {
    return {
       baseURL: instance.defaults.baseURL, 
      wishlistIcon,
      successMessage: '',
      currentSection: 'profile', // Відображаємо розділ "Особиста інформація" за замовчуванням
      user: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        city: ''
      },
      phoneNumber: '',  // Створюємо нову змінну для форматованого номеру
      errors: { phone: false },
      orders: [],
      expandedOrder: null, // Додаємо змінну для відкритого замовлення
      profileIcon, // Імпортована іконка профілю
      ordersIcon, // Імпортована іконка замовлень
      logoutIcon // Імпортована іконка для виходу
    };
  },
  computed: {
    ...mapGetters('wishlist', ['wishlistItems']), // Геттери для вішліста
  },
  mounted() {
    this.fetchWishlistProducts();
    this.fetchUserData(); // Викликаємо метод для отримання даних користувача та замовлень
  },
  methods: {
    ...mapActions('wishlist', ['removeFromWishList', 'fetchWishlistProducts']),
    ...mapActions('cart', ['addRemoveCart']),
    getImageUrl(imagePath) {
      return `${this.baseURL}${imagePath}`;
    },
    async addProdToCart(productId, qty = 1) {
      try {
        await this.addRemoveCart(productId, qty);
      } catch (error) {
        console.error('Помилка додавання товару в кошик:', error);
      }
    },
    async fetchUserData() {
      try {
        const token = Cookies.get('authToken');
        if (token) {
          const response = await instance.get('/api/v1/actions/orders/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.user = response.data.user; // Отримуємо інформацію про користувача
          this.orders = response.data.orders; // Отримуємо замовлення
          console.log(response.data);
          // Форматуємо номер телефону користувача
          if (this.user.phone_number) {
            this.phoneNumber = this.formatUserPhoneNumber(this.user.phone_number.slice(3));  // Видаляємо +38
          }
        }
      } catch (error) {
        await this.refreshToken();
        console.error('Помилка отримання даних користувача:', error);
      }
    },
    toggleOrderDetails(index) {
      this.expandedOrder = this.expandedOrder === index ? null : index; 
    },
    formatPhoneNumber() {
      let rawNumber = this.phoneNumber.replace(/\D/g, '');
      if (rawNumber.length > 10) rawNumber = rawNumber.slice(0, 10);

      if (rawNumber.length > 6) {
        rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
      } else if (rawNumber.length > 3) {
        rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      }
      this.phoneNumber = rawNumber;
    },
    formatUserPhoneNumber(phone) {
      let rawNumber = phone.replace(/\D/g, '');
      if (rawNumber.length > 10) rawNumber = rawNumber.slice(-10);
      if (rawNumber.length > 6) {
        return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
      } else if (rawNumber.length > 3) {
        return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      }
      return rawNumber;
    },
    validatePhoneNumber() {
      const rawNumber = this.phoneNumber.replace(/\D/g, '');
      if (rawNumber.length !== 10) {
        this.errors.phone = true;
        return false;
      }
      this.errors.phone = false;
      this.user.phone_number = '+38' + rawNumber; // Зберігаємо у форматі +38XXXXXXXXXX
      return true;
    },
    async saveUserData() {
      if (this.validatePhoneNumber()) {
        try {
          const token = Cookies.get('authToken');
          if (token) {
            await instance.put('/api/v1/actions/update/', this.user, {
              headers: { Authorization: `Bearer ${token}` },
            });
            this.successMessage = 'Дані успішно збережено!'; 
                  setTimeout(() => {
                this.successMessage = ''; 
              }, 3000);
            
          }
        } catch (error) {
          console.error('Помилка збереження даних користувача:', error);
        }
      } else {
        console.log('Невірний номер телефону');
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('uk-UA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    async refreshToken() {
      try {
        const refreshToken = Cookies.get('refresh_token');
        if (refreshToken) {
          const response = await instance.post('/api/v1/auth/refresh/', {
            refresh: refreshToken,
          });
          Cookies.set('authToken', response.data.access); // Оновлюємо токен доступу
          this.fetchUserData(); // Повторно запитуємо дані після оновлення токену
        } else {
          await this.$router.push('/login');
        }
      } catch (error) {
        console.error('Помилка оновлення токену:', error);
        await this.handleLogout(); // Виконуємо логаут, якщо не вдалося оновити токен
      }
    },
    async handleLogout() {
      Cookies.remove('authToken'); // Видаляємо токен
      Cookies.remove('refresh_token'); // Видаляємо refresh токен
      await this.$router.push('/login'); // Перенаправляємо на сторінку логіну
      window.location.reload(); // Оновлюємо сторінку після перенаправлення
    }
  },
  
};
</script>

<style scoped>
.account-container {
  position: relative;
  width: calc(100% - 128px);
  margin: 0 auto;
}
.menu-column img {
  width: 32px;
  height: 32px;
  position: relative;
  display: block;
}
.profile {
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-gap: 48px;
  min-height: 600px;
  margin-top: 20px;
}
.navigation {
  position: relative;
  display: grid;
  grid-gap: 16px;
  background-color: var(--clr-1-25);
  border-radius: 16px;
  min-height: 240px;
}
.navigation li {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 32px;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
}
.navigation li:hover{
  cursor: pointer;
  background: whitesmoke;
}
.content { 
  position: relative;
  display: grid;
  grid-gap: 24px;
  border-radius: 16px;
  border: 2px solid var(--grey-light);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.edit-button {
  background: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.profile-info-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile-info-item input {
  width: calc(95% - 4px);
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 1rem;
}
.label {
  font-weight: 600;
  color: #666;
}

.value {
  font-weight: 400;
  color: #333;
}
.content {
  width: 70%;
}
.header button {
  font-size: 18px;
  font-weight: 500;
}
.orders-section {
  margin-top: 20px;
}

.order {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.order-summary:hover {
  background-color: #f0f0f0;
}

.order-number, .order-status, .order-delivery, .order-total, .order-time {
  flex-basis: 20%;
  text-align: center;
}

.order-products {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.order-products h3 {
  margin-bottom: 10px;
}

.order-products ul {
  list-style: none;
  padding: 0;
}

.order-products ul li {
  margin-bottom: 5px;
}
.phone-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 960px) {
  .content {
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  .header {
    display: block;
  }
  .profile {
    grid-template-columns: 56px 1fr;
    grid-gap: 16px;
    margin-left: -20px;
  }
  .menu-text {
    display: none;
  }
  .profile .navigation li {
    display: grid;
    grid-template-columns: 0;
    grid-gap: 32px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-radius: 4px;
  }
  .account-container { 
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .profile{ 
    margin-left: -40px;
  }
  .profile-info-grid {
    grid-template-columns: unset;
  }
}

.status-circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
}
.green {
  background-color: green;
}
.red {
  background-color: red;
}
.success-message {
  color: green;
  font-weight: bold;
}
.welcome-message {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.wishlist-section {
  margin-top: 20px;
}
.wishlist-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.wish_list_item {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wish_list_img {
  grid-row: 1 / span 2;
  grid-column: 1 / 2;
  align-self: center;
  justify-self: center;
}

.wish_list_img img {
  max-width: 100%;
  border-radius: 4px;
}

.wist_list_title {
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wist_list_title a {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.wist_list_title a:hover {
  text-decoration: underline;
}

.wist_list_title_bnt {
  background: none;
  border: none;
  cursor: pointer;
}

.wist_list_title_bnt img {
  width: 20px;
  height: 20px;
}

.wish_list_price {
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wish_list_price p {
  font-size: 1rem;
  margin: 0;
}

.crossed-price {
  text-decoration: line-through;
  color: #999;
}

.disc_price {
  color: red;
  font-weight: bold;
}

.wish_list_price button {
  text-transform: uppercase;
  background: #000;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  transition: background 0.3s;
}

.wish_list_price button:hover {
  background: #333;
}

.empty-wishlist-message {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

@media (max-width: 350px){
  .wishlist-section {
    padding: 0;
  }
}

</style>