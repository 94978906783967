<template>
    <div class="order-summary">
      <h3>Підсумок замовлення</h3>
  
      <div>
        <span>Товари: </span>
        <span>{{ totalPrice }} грн</span>
      </div>
  
      <div class='price_block'>
        <span>Доставка: </span>
        <span v-if="deliveryType === 'nova_poshta'"> По тарифам перевізника. <br> При повній оплаті на сайті,- безкоштовна доставка.</span>
        <span v-else-if="isNumber(deliveryCost)" v-html="`${deliveryCost}`"></span>

        <span v-else>{{ deliveryCost }}</span>
      </div>
  
      <div class="cart_info_block">
        <div class="discount_prod">
          <form @submit.prevent="applyPromoCode" class="form-group">
            <input type="text" v-model="promoCode" placeholder="Введіть промокод">
            <button type="submit" class="apple_promo">Застосувати</button>
          </form>
          <p class="promo-error" v-if="error">{{ error }}</p>
        </div>
        <div class="cart_total_block">
          <div class="total_price">
            <p v-if="discount">Знижка за промокодом: {{ discount }} %.</p>
          </div>
        </div>
      </div>
  
      <div>
        <strong>Разом:</strong>
        <strong>{{ totalSum }} грн</strong>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import instance from '@/api/axiosInstance'; // Імпорт для API запитів
  
  export default {
    name: 'OrderSummary',
    data() {
      return {
        promoCode: '',  // Промокод
        discount: 0,    // Локальна знижка (у відсотках)
        error: ''       // Помилка для відображення
      };
    },
    computed: {
      ...mapState('checkout', ['deliveryType', 'paymentMethod', 'totalAmount']), // Підключаємо state зі Vuex
      totalPrice() {
        return this.$store.getters['cart/totalPrice']; // Отримуємо загальну вартість товарів
      },
      delivery_price(){
        return this.$store.getters['checkout/deliveryCost'];
      },
      deliveryCost() {
        // Логіка для розрахунку вартості доставки в залежності від типу доставки та оплати
        if (this.totalPrice >= 3500 || this.paymentMethod === 'card') {
          return "Безкоштовно"; // Безкоштовна доставка для замовлень більше 3500 грн або для повної оплати картою
        } else if (this.deliveryType === 'courier') {
          return `150 грн |
          При замовленні від 3500 грн, доставка безкоштовна.`;

        } else if (this.deliveryType === 'pickup') {
            return  "Безкоштовно";
        }
        
        return `При повній оплаті по новій пошті,- доставка безкоштована.`;
      },
      deliveryPrice() {
        if (this.deliveryType === 'courier' && this.totalPrice && this.totalPrice < 3500) {
          return 150;
        }
        return 0;
      },

      totalSum() {
        const discountAmount = (this.totalPrice * this.discount) / 100; // Розрахунок суми знижки у відсотках
        const finalTotal = this.totalPrice - discountAmount + (this.isNumber(this.deliveryPrice) ? this.deliveryPrice : 0);
        this.$store.dispatch('checkout/saveTotalSum', );
        return finalTotal.toFixed(2); // Повертаємо загальну суму
      },
      isNumber() {
        return value => !isNaN(parseFloat(value)) && isFinite(value); // Функція для перевірки, чи є значення числом
      }
    },
    methods: {
      ...mapActions('checkout', ['applyPromoCode', 'saveTotalSum', 'savePaymentMethod']), // Додаємо дію для промокоду і оплати
        applyPromoCode() {
            const promo_Code = this.promoCode;
            instance.post('/api/v1/orders/promocode/validate/', { code: promo_Code })
            // eslint-disable-next-line
            .then(response => {
                this.discount = response.data.discount;
                this.$store.dispatch('checkout/applyPromoCode', promo_Code); // Викликаємо action з правильним шляхом
                this.error = ''; // Очищуємо помилку, якщо промокод дійсний
            })
            .catch(error => {
                this.error = error.response.data.error || 'Сталася помилка';
            });
        },
        updateTotalSum() {
            this.totalSum;

        },
    watch: {
      // Спостерігаємо за ключовими даними для обчислення загальної суми
      deliveryCost() {
        this.updateTotalSum();
      },
      totalPrice() {
        this.updateTotalSum();
      },
      discount() {
        this.updateTotalSum();
      }
    },
    mounted() {
      this.updateTotalSum(); // Оновлюємо тотал при завантаженні компонента
    },}
  };
  </script>
  
  <style scoped>
  .order-summary {
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .discount_prod button {
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #000;
    cursor: pointer;
    transition: 0.35s;
    letter-spacing: 1px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    font-family: 'MaisonNeue';
    width: 100%;
    margin-bottom: 5px;
  }
  
  .discount_prod button:hover {
    background: #000;
    color: #fff;
  }
  .price_block {
    margin: 5px 0;
  }
  .promo-error {
    color: red;
  }
  </style>