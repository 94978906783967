<template>
    <div class='product_data _container'>
      <div v-if="selectedVariant?.images && selectedVariant?.images.length > 0" class="product_imgs">
        <!-- Головна карусель для великих фото -->
        <Carousel 
        id="gallery" 
        :items-to-show="1" 
        :wrap-around="false" 
        v-model="currentSlide"
        :i18n="i18nText"
      >
        <Slide v-for="(image, index) in selectedVariant.images" :key="index">
          <div class="carousel__item">
            <img :src="image.image" alt="MAIN PHOTO" class="main-photo" />
          </div>
        </Slide>
      </Carousel>

      <!-- Мініатюри для навігації -->
      <Carousel 
        id="thumbnails" 
        :items-to-show="itemsToShow" 
        :wrap-around="false" 
        v-model="currentSlide"
        :i18n="i18nText"
      >
        <Slide v-for="(image, index) in selectedVariant.images" :key="index">
          <div class="carousel__item" @click="slideTo(index)">
            <img :src="image.image" alt="SMALL PHOTO" class="thumbnail" />
          </div>
        </Slide>
      </Carousel>

    </div>
      <div class="proudct_imgs_mobile">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(image, index) in selectedVariant?.images" :key="index">
            <div class="carousel__item">  <img :src="image.image" alt="MAIN PHOTO" class="main-photo" /> </div>
            </Slide>
            <template #addons>
            <Navigation />
            </template>
        </Carousel>
      </div>
      <div class="product_info">
        <div class="product_header">
          <div class="product_name">
            <p>{{ product.name }}</p>
            <p>{{ selectedVariant?.code }}</p>
          </div>
          <div class="product_price">
            <p :class="{ 'crossed-price': selectedVariant?.discount_price > 0 }">
              {{ selectedVariant?.price }} грн
            </p>
            <p v-if="selectedVariant?.discount_price > 0">
              {{ selectedVariant?.discount_price }} грн
            </p>
        </div>
      </div>

        <div class="product-action-wrapper">
          <button class="product-action" @click="addProdToCart(selectedVariant.id)">Додати в кошик</button>
          <button class="product-action" @click="addProdToWishlist(selectedVariant.id)">Додати в обране</button>
        </div>
  
        <div class="product_subheader">
          <div class="product_human">
            <p>{{ product.human }}</p>
          </div>
       
        </div>
        
        <div class="product_dropdawn">
          <div class="product_dropdawn" v-if="product.key_ingredients && product.key_ingredients.length > 1">
            <div class="dropdawn-item" @click="toggleDropdown('key_ingredients')">
              Ключові інгредієнти
              <span :class="activeDropdown === 'prescription' ? 'arrow-up' : 'arrow-down'"></span>
            </div>
            <transition name="slide">
              <div v-if="activeDropdown === 'key_ingredients'" class="dropdown-content">
                <span v-html="product.key_ingredients"></span>
              </div>
            </transition>
          </div>
          </div>
          <div v-if="product.variants && product.variants.length > 0" class="product_dropdawn">
  <div class="dropdawn-item" @click="toggleDropdown('variants')">
    {{ selectedVariant?.value || 'Оберіть варіант' }} {{ product.unit }}
    <span :class="activeDropdown === 'variants' ? 'arrow-up' : 'arrow-down'"></span>
  </div>
  <transition name="slide">
    <div v-if="activeDropdown === 'variants'" class="dropdown-content">
      <div
        v-for="variant in product.variants"
        :key="variant.id"
        class="dropdown-option"
        @click="selectVariant(variant)"
      >
        {{ variant.value }} {{ product.unit }}
      </div>
    </div>
  </transition>
</div>

    
    <div class="product_description">
          <div>{{ product.description }}</div>
          <div class="long_description" v-if="showLongDescription">
            <span v-html="product.description_text"></span>
          </div>
          <button @click="toggleLongDescription">
            {{ showLongDescription ? 'Звернути' : 'Розвернути опис' }}
          </button>
    </div>

  
      
        
        <div class="quik_view" v-if="product.product_types.length >0 || product.skin_types.length > 0">
            <div class="view_header">Швидкий перегляд</div>

            <div class="view_content" v-if="product.product_types && product.product_types.length > 0">
                <div class="view_content_header">Тип продукту</div>
                <div class="view_content_text" v-for="product_type in product.product_types" :key="product_type.id">
                    {{ product_type.name }}
                </div>
            </div>
            <div class="view_content" v-if="product.skin_types && product.skin_types.length > 0">
                <div class="view_content_header">Тип шкіри</div>
                <div class="view_content_text"  v-for="skin_type in product.skin_types" :key="skin_type.id">
                    {{ skin_type.name }}
                    <div class="bullet_point"></div>
                </div>
    </div>
</div>

        <div class="product_dropdawn">
          <div class="product_dropdawn" v-if="product.presscription && product.presscription.length > 1">
            <div class="dropdawn-item" @click="toggleDropdown('prescription')">
              Призначення
              <span :class="activeDropdown === 'prescription' ? 'arrow-up' : 'arrow-down'"></span>
            </div>
            <transition name="slide">
              <div v-if="activeDropdown === 'prescription'" class="dropdown-content">
                <span v-html="product.presscription"></span>
              </div>
            </transition>
          </div>

  
          <div class="dropdawn-item" @click="toggleDropdown('application')">
            Застосування
            <span :class="activeDropdown === 'application' ? 'arrow-up' : 'arrow-down'"></span>
          </div>
          <transition name="slide">
            <div v-if="activeDropdown === 'application'" class="dropdown-content">
              <span v-html="product.application"></span>
            </div>
          </transition>
          
          <div class="dropdawn-item" @click="toggleDropdown('composition')">
            Склад
            <span :class="activeDropdown === 'composition' ? 'arrow-up' : 'arrow-down'"></span>
          </div>
          <transition name="slide">
            <div v-if="activeDropdown === 'composition'" class="dropdown-content">
              <span v-html="product.composition"></span>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { Carousel, Slide } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';
  import { mapActions, mapState } from 'vuex';
  import instance from '@/api/axiosInstance';


  export default defineComponent({
    name: 'ProductPage',
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        product: {}, // Продукт
        currentSlide: 0, // Поточний слайд для каруселі
        showLongDescription: false, // Показати чи приховати довгий опис
        activeDropdown: null,
        selectedVariantId: null, // Вибраний ID варіанту
      i18nText: {
        ariaNextSlide: 'Наступний слайд',
        ariaPreviousSlide: 'Попередній слайд',
        ariaNavigateToSlide: 'Перейти до слайду {slideNumber}',
        ariaGallery: 'Галерея',
        itemXofY: 'Слайд {currentSlide} з {slidesCount}',
        iconArrowUp: 'Стрілка вгору',
        iconArrowDown: 'Стрілка вниз',
        iconArrowRight: 'Стрілка вправо',
        iconArrowLeft: 'Стрілка вліво',
      },
      };
    },
    computed: {
      ...mapState(['cart']),
      selectedVariant() {
        // Ensure product and its variants are defined
        if (this.product && this.product.variants) {
          return this.product.variants.find((variant) => variant.id === this.selectedVariantId) || {}; // Повертаємо порожній об'єкт, якщо варіант не знайдено
        }
        return {}; // Повертаємо порожній об'єкт, якщо продукт або варіанти недоступні
      },
      itemsToShow() {
      return Math.min(this.selectedVariant?.images.length || 0, 4);
    },
    },
    created() {
      this.fetchProductData();
    },
    mounted() {
    this.fetchProductData();
   },

    methods: {

  selectVariant(variant) {
    this.selectedVariantId = variant.id;
    this.updateVariant(); // Викликаємо метод оновлення варіанта
    this.activeDropdown = null; // Закриваємо дропдаун
  },

  updateVariant() {
    this.currentSlide = 0;
    // Логіка оновлення варіанта, якщо потрібно
    console.log('Вибраний варіант:', this.selectedVariant);
  },
      toggleDropdown(dropdownName) {
        this.activeDropdown = this.activeDropdown === dropdownName ? null : dropdownName;
      },
      fetchProductData() {
  const slug = this.$route.params.slug;
  instance.get(`/api/v1/shop/product/${slug}/`)
    .then((response) => {
      this.product = response.data;
      if (this.product.variants && this.product.variants.length) {
        this.selectedVariantId = this.product.variants[0].id; // Встановлюємо вибраний ID на перший варіант
      }
      this.currentSlide = 0; // Скидаємо слайд на перший

      // Пушимо подію productView до даталайера
      window.dataLayer.push({
        'event': 'productView',
          'ecommerce': {
           'detail': {
              'products': [{
                'id': this.product.id,
                'name': this.product.name,
                'price': this.selectedVariant?.price,  // Ціна варіанту, якщо він доступний
                'discount_price': this.selectedVariant?.discount_price,  // Знижена ціна, якщо є
                'code': this.selectedVariant?.code 
              }]
            }
          }
       });
      })
      .catch((error) => console.error('Error fetching product data:', error));  // Ловимо помилки API-запиту
    },

     slideTo(index) {
      this.currentSlide = index;
    },
      toggleLongDescription() {
        this.showLongDescription = !this.showLongDescription;
      },
      ...mapActions('cart', ['addRemoveCart']), 
      ...mapActions('wishlist', ['addToWishList']), 
      
      async addProdToCart(variantId, qty) {
        this.$emit('home_close_cart');
        try {
            this.addRemoveCart(variantId, qty);  // Передаємо ID варіанту і кількість, яку користувач вибрав
        } catch (error) {
            console.error("Error adding product to cart:", error);
      }},
     
      
      async addProdToWishlist(variantId) {
        this.$emit('home_close_wish');
        try {
          // Додаємо до вішлісту без передачі кількості
          this.addToWishList(variantId);
        } catch (error) {
          console.error("Error adding product variant to wishlist:", error);
        }
    },
  
    },
  });
  </script>
  
  <style scoped>
  .product_data {
    display: flex;
    align-items: center;
  }
  
  .product_imgs {
    width: 50%;
  }
  
  .thumbnail-carousel {
    height: 300px;
    overflow-y: auto;
  }
  
  .gallery-carousel {
    height: auto;
  }
  .carousel {
    width: 90%;
  }
  
  .carousel__track {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    position: relative;
    overflow-y: auto;
    height: 100%;
    transition: none;
  }
  .carousel__item {
  display: inline-block;
  margin: 0 5px; /* Відступи між мініатюрами */
}

.thumbnail {
  width: 100px; /* Можна коригувати розмір мініатюри */
  height: auto;
  object-fit: cover;
}
  .main-photo {
    width: 100%;
    height: auto;
  }
  
 
  .thumbnail:hover {
    opacity: 0.8;
  }
  
  .product_info {
    width: 50%;
    padding: 20px;
  }
  
  .product_header {
    display: flex;
    justify-content: space-between;

  }
  
  .product_subheader {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 0 0;
    align-items: center;
  }
  
  .product_varia select {
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-top: 15px;
  }
  
  .product_description {
    margin-top: 20px;
  }
  
  .product_description button {
    border: none;
    background: none;
    margin-left: -6px;
    text-decoration: underline;
    font-style: italic;
  }
  
  .product-action-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    position: static;
    width: 100%;
  }
  
  .product-action {
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #000;
    cursor: pointer;
    transition: 0.35s;
    width: 100%;
    letter-spacing: 1px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 5px;
  }
  .quik_view {
    border: 1px solid black; /* Чорна лінія по краях */
    padding: 20px;
    margin: 20px 0;
    font-family: Arial, sans-serif;
}

.view_header {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: start;
    margin-bottom: 15px;
    border-bottom: 1px solid black; /* Чорна лінія під заголовком */
    padding-bottom: 10px;
}

.view_content {
    margin-bottom: 15px; /* Відстань між секціями */
}

.view_content_header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.view_content_text {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product_dropdawn {
  margin: 25px 0;
}

.dropdawn-item {
  position: relative;
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid #000;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
}

.dropdown-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 500px;
  padding: 10px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #000;
  margin-top: 5px;
  z-index: 100;
}

.dropdown-option {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.arrow-down::before,
.arrow-up::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(45deg);
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.arrow-up::before {
  transform: rotate(-135deg);
}


/* Стиль для квадратика з крапкою */
.bullet_point {
    width: 10px;
    height: 10px;
    border: 1px solid black; /* Чорна рамка навколо квадрата */
    display: inline-block;
    margin-right: 10px;
    position: relative;
    background-color: white; /* Білий фон для квадрата */
}

.bullet_point::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: black; /* Чорна крапка в центрі */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}
  .product-action:hover {
    color: #fff;
    background: #000;
  }
  
  .product_dropdawn {
    margin: 25px 0;
  }
  
  .dropdawn-item {
    position: relative;
    cursor: pointer;
    padding: 10px 15px;
    border: 1px solid #000;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 500px;
    padding: 10px;
    font-weight: 500;
  }
  .dropdown-content span {
    font-size: 12px;
    font-weight: 400;
  }
  
  .arrow-down::before, .arrow-up::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    transform: rotate(45deg);
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .arrow-up::before {
    transform: rotate(-135deg); 
  }

  .proudct_imgs_mobile {
    display:none;
  }
@media (max-width: 768px){
    .product_data {
        flex-direction: column;
    }
    .product_imgs {
        display: none;
    }
    .product_info {
        width: 100%;
        padding: 0px;
    }
    .proudct_imgs_mobile {
        display: block;
    }
    .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    .carousel__slide {
    padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
    }

    .quik_view {
      margin-top: 100px;
    }
}   
#thumbnails {
  display: flex;
  justify-content: flex-start; /* Вирівнювання по лівому краю */
}
  </style>
  