import instance from '@/api/axiosInstance';  // Імпортуємо axios інстанс

export default {
  namespaced: true,
  state: {
    wishlist: JSON.parse(localStorage.getItem('wishlist')) || [],  // Завантажуємо збережені дані з localStorage, якщо вони є
    wishlistProducts: []  // Зберігаємо продукти з вішліста тут
  },
  mutations: {
    ADD_TO_WISHLIST(state, variantId) {
      if (!state.wishlist.includes(variantId)) {
        state.wishlist.push(variantId);
        localStorage.setItem('wishlist', JSON.stringify(state.wishlist));  // Зберігаємо оновлений список у localStorage
        console.log(`Added to wishlist: Variant ID - ${variantId}`);
      } else {
        console.log(`Variant ID - ${variantId} is already in the wishlist.`);
      }
    },
    REMOVE_FROM_WISHLIST(state, variantId) {
      state.wishlist = state.wishlist.filter(id => id !== variantId);
      localStorage.setItem('wishlist', JSON.stringify(state.wishlist));  // Оновлюємо дані в localStorage після видалення
      console.log(`Removed from wishlist: Variant ID - ${variantId}`);
    },
    SET_WISHLIST_PRODUCTS(state, products) {
      state.wishlistProducts = products;
    }
  },
  actions: {
    addToWishList({ commit, dispatch }, variantId) {
      commit('ADD_TO_WISHLIST', variantId);
      dispatch('fetchWishlistProducts');  // Оновлюємо список товарів
    },
    removeFromWishList({ commit, dispatch }, variantId) {
      commit('REMOVE_FROM_WISHLIST', variantId);
      dispatch('fetchWishlistProducts');  // Оновлюємо список товарів
    },
    async fetchWishlistProducts({ state, commit }) {
      if (state.wishlist.length === 0) {
        commit('SET_WISHLIST_PRODUCTS', []);  // Якщо немає товарів, очищуємо список
        return;
      }
      try {
        const response = await instance.post('/api/v1/shop/wishlist/', {
          variant_ids: state.wishlist
        });
        commit('SET_WISHLIST_PRODUCTS', response.data);
      } catch (error) {
        console.error("Error fetching wishlist products:", error);
      }
    }
  },
  getters: {
    wishlistItems(state) {
      return state.wishlistProducts;  // Повертаємо список товарів з вішліста
    },
    wishlistItemCount(state) {
      console.log(state.wishlistProducts.length)
      return state.wishlistProducts.length;  
      
    }
  },


};
