<template>
    <div class="purchase_content _container">
      <h5 v-if="orderId"> Номер замовлення:  {{ orderId }}</h5>
  
      <div v-if="localCartItems.length > 0">
        <ul>
          <li v-for="(item, index) in localCartItems" :key="index">
            Назва: {{ item.product_name }} | Кількість: {{ item.qty }} | 
            <span v-if="item.discount_price > 0" class="discount_price">{{ item.discount_price }} UAH</span>
            <span v-if="item.discount_price > 0">  {{ item.price }} UAH</span>
            <span v-else>{{ item.price }} UAH</span>
          </li>
        </ul>
        <p style="font-size: 22px">Загальна сума: {{ localTotalPrice }} UAH</p>
        <!-- <p v-if="orderId">Номер замовлення: {{ orderId }}</p> -->
      </div>
      
      <div v-else>
        <p>Кошик порожній.</p>
      </div>
    </div>
  </template>
  
  <script>
  import instance from '@/api/axiosInstance';  // Імпортуємо axios інстанс
  
  export default {
    name: 'PurchaseContent',
    data() {
      return {
        localCartItems: [],  // Локальне сховище для даних кошика
        localTotalPrice: 0,  // Локальне сховище для загальної суми
        orderId: null        // Зберігаємо номер замовлення
      };
    },
    methods: {
      async loadCartData() {
        // Отримуємо дані про товари з localStorage
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        const storedOrderId = localStorage.getItem('order_id');
  
        // Якщо є дані про кошик, витягуємо variantId товарів
        if (storedCart) {
          const variantIds = storedCart.map(item => item.variantId);
  
          // Виконуємо серверний запит для отримання інформації про товари
          try {
            const response = await instance.post('/api/v1/shop/wishlist/', { variant_ids: variantIds });
            
            // Оновлюємо дані в localCartItems
            this.localCartItems = response.data.map(product => {
              const cartItem = storedCart.find(item => item.variantId === product.id);
              return {
                ...product,
                qty: cartItem ? cartItem.qty : 1  // Зберігаємо кількість із кошика
              };
            });
  
            // Обчислюємо загальну суму
            this.localTotalPrice = this.localCartItems.reduce((total, item) => {
              const price = item.discount_price > 0 ? item.discount_price : item.price;
              return total + price * item.qty;
            }, 0).toFixed(2);
  
          } catch (error) {
            console.error('Помилка отримання даних про товари:', error);
          }
        }
  
        // Якщо є order_id, зберігаємо його
        if (storedOrderId) {
          this.orderId = storedOrderId;
        }
      },
      clearCartData() {
        
        localStorage.removeItem('cart');
      }
    },
    created() {
      this.loadCartData();
  
      this.clearCartData();
    }
  };
  </script>
  
  <style scoped>
  .purchase_content {
    padding: 20px;
  }
  
  /* Стилі для відображення знижки */
  .original_price {
    text-decoration: line-through;
    color: red;
    margin-right: 10px;
  }
  
  .discount_price {
    color: red;
    font-weight: bold;
  }
  
  .normal_price {
    font-weight: bold;
  }
  </style>
  