<template>
  <div class="login-container">
    <form @submit.prevent="handleRegister" class="login-form _register">
      <h2>Реєстрація користувача</h2>

      <!-- Ім'я та Прізвище -->
      <div class="form-row">
        <div class="form-group">
          <label for="name"></label>
          <input
            type="text"
            id="name"
            v-model="name"
            @blur="validateName"
            required
            placeholder="Ім'я"
          />
          <span v-if="nameErrors.length" class="error-message">{{ nameErrors[0] }}</span>
        </div>
        <div class="form-group">
          <label for="surname"></label>
          <input
            type="text"
            id="surname"
            v-model="surname"
            @blur="validateSurname"
            required
            placeholder="Прізвище"
          />
          <span v-if="surnameErrors.length" class="error-message">{{ surnameErrors[0] }}</span>
        </div>
      </div>

      <!-- Email та Телефон -->
      <div class="form-row">
        <div class="form-group">
          <label for="email"></label>
          <input
            type="email"
            id="email"
            v-model="email"
            @blur="validateEmail"
            required
            placeholder="Email"
          />
          <span v-if="emailErrors.length" class="error-message">{{ emailErrors[0] }}</span>
        </div>
        <div class="form-group">
          <label for="phone"></label>
          <input
            type="text"
            id="phone"
            v-model="phone"
            @blur="validatePhone"
            required
            placeholder="Номер телефону"
          />
          <span v-if="phoneErrors.length" class="error-message">{{ phoneErrors[0] }}</span>
        </div>
      </div>

      <!-- Пароль та Підтвердження паролю -->
      <div class="form-row">
        <div class="form-group password-group">
  <label for="password"></label>
  <input
    :type="showPassword ? 'text' : 'password'"
    id="password"
    v-model="password"
    @blur="validatePassword"
    required
    placeholder="Пароль"
  />
  <span class="toggle-password" @click="togglePassword">
    <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
      <circle cx="12" cy="12" r="3"></circle>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
      <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8s4-8 11-8c3.43 0 6.58 1.28 9 3.54"></path>
      <path d="M1 1l22 22"></path>
    </svg>
  </span>
  <span v-if="newPasswordErrors.length" class="error-message">{{ newPasswordErrors[0] }}</span>
</div>

<div class="form-group password-group">
  <label for="confirmPassword"></label>
  <input
    :type="showConfirmPassword ? 'text' : 'password'"
    id="confirmPassword"
    v-model="confirmPassword"
    @blur="validateConfirmPassword"
    required
    placeholder="Пароль ще раз"
  />
  <span class="toggle-password" @click="toggleConfirmPassword">
    <svg v-if="!showConfirmPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
      <circle cx="12" cy="12" r="3"></circle>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
      <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8s4-8 11-8c3.43 0 6.58 1.28 9 3.54"></path>
      <path d="M1 1l22 22"></path>
    </svg>
  </span>
  <span v-if="confirmPasswordErrors.length" class="error-message">{{ confirmPasswordErrors[0] }}</span>
</div>

      </div>

      <button type="submit" class="login-button">Реєстрація</button>
      <div v-if="loading" class="loader">Зачекайте...</div>
      <div class="form-links">
        <router-link to="/login">Вже маєте акаунт?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import load_animation from '@/assets/img/ico/loading.svg';

export default {
  name: "RegisterPage",
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      phoneErrors: [],
      nameErrors: [],
      surnameErrors: [],
      emailErrors: [],
      newPasswordErrors: [],
      confirmPasswordErrors: [],
      loading: false, 
    };
  },
  computed: {
    load_animation() {
      return load_animation;
    },
  },
  methods: {
    ...mapActions('auth', ["register"]),

    // Показ/приховування паролю
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    // Валідація полів
    validateName() {
      const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ'’\s-]+$/;
      this.nameErrors = [];
      if (!nameRegex.test(this.name) || this.name.trim().length < 2) {
        this.nameErrors.push("Введіть коректне ім'я (мінімум 2 символи, без спеціальних символів)");
      }
    },
    validateSurname() {
      const surnameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ'’\s-]+$/;
      this.surnameErrors = [];
      if (!surnameRegex.test(this.surname) || this.surname.trim().length < 2) {
        this.surnameErrors.push("Введіть коректне прізвище (мінімум 2 символи, без спеціальних символів)");
      }
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailErrors = [];
      if (!emailRegex.test(this.email)) {
        this.emailErrors.push("Введіть коректний email");
      }
    },
    validatePhone() {
      const phoneRegex = /^\+?[0-9]{10,15}$/;
      this.phoneErrors = [];
      if (!phoneRegex.test(this.phone)) {
        this.phoneErrors.push("Невірний формат телефону");
      }
    },
    validatePassword() {
      const errors = [];
      if (this.password.length < 8) {
        errors.push("Пароль занадто короткий. Мінімум 8 символів.");
      }
      if (!/[A-Z]/.test(this.password)) {
        errors.push("Пароль повинен містити хоча б одну велику літеру.");
      }
      if (!/[a-z]/.test(this.password)) {
        errors.push("Пароль повинен містити хоча б одну малу літеру.");
      }
      if (!/[0-9]/.test(this.password)) {
        errors.push("Пароль повинен містити хоча б одну цифру.");
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.password)) {
        errors.push("Пароль повинен містити хоча б один спеціальний символ.");
      }
      this.newPasswordErrors = errors;
    },
    validateConfirmPassword() {
      this.confirmPasswordErrors = [];
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordErrors.push("Паролі не збігаються");
      }
    },

    async handleRegister() {
      this.loading = true;
      this.clearErrors(); // Очищення попередніх помилок

      this.validateName();
      this.validateSurname();
      this.validateEmail();
      this.validatePhone();
      this.validatePassword();
      this.validateConfirmPassword();

      // Перевірка, чи є помилки перед відправкою
      if (this.hasErrors()) {
        this.loading = false;
        return;
      }

      try {
        await this.register({
          first_name: this.name,
          last_name: this.surname,
          email: this.email,
          phone_number: this.phone,
          password: this.password,
          re_password: this.confirmPassword,
        });
        this.$router.push("/registration-success");
      } catch (error) {
        console.error("Помилка реєстрації:", error);
        alert("Не вдалося зареєструватися. Спробуйте ще раз.");
      } finally {
        this.loading = false;
      }
    },

    // Очищення всіх помилок
    clearErrors() {
      this.nameErrors = [];
      this.surnameErrors = [];
      this.emailErrors = [];
      this.phoneErrors = [];
      this.newPasswordErrors = [];
      this.confirmPasswordErrors = [];
    },

    // Перевірка, чи є помилки у всіх полях
    hasErrors() {
      return (
        this.nameErrors.length ||
        this.surnameErrors.length ||
        this.emailErrors.length ||
        this.phoneErrors.length ||
        this.newPasswordErrors.length ||
        this.confirmPasswordErrors.length
      );
    },
  },
};
</script>

<style src="@/assets/css/Login.css"></style>
<style src="@/assets/css/load_animation.css"></style>
