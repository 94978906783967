<template>
    <div class="about-us _container">
        <div class="rte">
                <div class="content_home_about_us">	
                    <div class="content-cart">
                        <div class="content-cart-ico">
                            <a href="/contacts" class="content_ico"><img class="icon-philosophy" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/icon-about-us.svg?v=1647935391" alt="icon about us"></a>
                        </div>
                        <div class="content-cart-info">
                            <h3 class="title"><a href="/contacts">ПРО НАС</a></h3>
                            <p><a href="/about-us">
                                Помітні результати завдяки ефективній косметиці та якісному догляду — саме це ми пропонуємо нашим клієнтам. Ми завжди спираємося на науку та дослідження. Усі продукти розробляються у власній лабораторіі з використанням найкращих активних інгредієнтів і технологій для догляду за шкірою.

                            </a></p>
                        </div>
                    </div>
                    <div class="content-cart">
                        <div class="content-cart-ico">
                            <a href="/contacts" class="content_ico"><img class="icon-philosophy" src="https://cdn.shopify.com/s/files/1/0325/6398/6572/files/icon-sustainability.svg?v=1647935391" alt="icon sustainability" style="max-width:100%"></a>
                        </div>
                        <div class="content-cart-info">
                            <h3 class="title"><a href="/contacts">СТІЙКІСТЬ</a></h3>
                            <p><a href="/contacts">
                                У Niche Beauty Lab ми піклуємось про здоров’я вашої шкіри і разом із цим завжди дбаємо про здоров’я планети. Для нас важливий баланс в усьому. Тому ми прагнемо стійкості у виробництві, застосовуємо методи переробки. І таким чином зменшуємо наш вплив на довкілля настільки, наскільки це можливо.
                            </a></p>
                        </div>
                    </div>
                </div>
              </div>
    </div>
</template>

<style scoped>

.about-us {
    margin: 15px 0;
    gap: 12px;
}
.content_home_about_us{
    display: flex;
    margin-bottom: 3rem !important;
}
@media (max-width: 768px) { 
    .content_home_about_us {
        display: block;
    }
}
.content-cart { 
    display: flex;
    align-items: center;
    gap: 10px;
}
.content-cart-ico {
    width: 50%;
    min-width: 60px;
}
.content-cart-info h3{
    font-size: 18.5px;
    font-weight: 600;
}
.content-cart-info p {
    letter-spacing: 0.5px;
    line-height: 19.5px;
    font-weight: 400;
    font-size: 13px;
}


</style>