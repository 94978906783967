<template>
  <div class="page_home">
    <!-- <section class="video_section">
      <div style="margin-bottom: 0px !important;">
        <video class="player-wrapper_desktop" width="100%" height="100%" id="vid" muted playsinline loop autoplay>
          <source id="hvid" type="video/mp4" src="https://api.nichebeautylab.com.ua/media/BARRIER.video (3456 x 1120 пікс.).mp4">
        </video>
      </div>
    </section> -->
    <BannerCarousel :banners="banners" />
    <!-- <section class="quiz_component">
      <Quiz_Component />
    </section> -->
    <section class="hottest">
      <div class="section_header">
        <h2 class="section-header__title">бестселлери</h2>
      </div>
      <SliderComponent   @si_close_cart="HomeCloseCart"  @si_close_wish="HomeCloseWish"/>
    </section>
    <section class="collection_component">
      <CollectionComponent />

    </section>
    <section class="index">
      <Index_section />
    </section>
    <section class="about-us">
      <AboutUsSection />
    </section>
    <section class="feture_on">
      <FeaturedOnSection />
    </section>
    <SubscribleSection />
  </div>
  
</template>

<script>
import SliderComponent from '@/components/Slider.vue';
// import Quiz_Component from '@/components/QuizComponents.vue';
import CollectionComponent from '@/components/CollectionSection.vue';
import Index_section from '@/sections/Index_section.vue';
import AboutUsSection from '@/sections/AboutUsSection.vue';
import FeaturedOnSection from '@/sections/FeaturedOnSection.vue';
import SubscribleSection from '@/sections/SubscribleSection.vue';
import BannerCarousel from '@/components/BannerCarousel.vue';
import instance from '@/api/axiosInstance';

export default {
  components: {
    // Quiz_Component,
    SliderComponent,
    CollectionComponent,
    Index_section,
    AboutUsSection,
    FeaturedOnSection,
    SubscribleSection,
    BannerCarousel,

  },
  data() {
    return {
      banners: [],
    };
  },
  methods: {
    HomeCloseCart(){
      console.log("HOME_cart")
      this.$emit('home_close_cart');
    },
    HomeCloseWish(){
      console.log("HOME_wish")
      this.$emit('home_close_wish');
    },
    async fetchBanners() {
      try {
        const response = await instance.get('/api/v1/shop/home-banner/'); // Запит до API
        if (response.data) {
          this.banners = response.data; // Зберігаємо банери
        }
      } catch (error) {
        console.error('Error fetching banners:', error); // Лог помилки
      }
    },
  },
  mounted() {
    this.fetchBanners(); // Завантаження банерів при монтуванні компонента
  },
};

</script>

<style scoped>
.section-header__title {
  line-height: normal;
  font-size: 20px;
  margin: 1.5rem 0;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  letter-spacing: 5px;
}
.about-us {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>