<template>
  <div>
    <BannerCarousel :banners="brandData.big_banners" />
    <ProductCards :products="brandData.products_with_variants" @pc_s_close_cart="PTCloseCart" @pc_s_close_wish="PTCloseWish" />
  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router'; // Імпортуємо useRoute
import instance from '@/api/axiosInstance';
import BannerCarousel from '@/components/BannerCarousel.vue'; // Імпортуємо ваш компонент
import ProductCards from '@/components/ProductCards.vue';

export default {
  name: 'BrandPageComponent',
  components: {
    BannerCarousel, 
    ProductCards,
  },
  methods: {
      PTCloseCart() {
      this.$emit('home_close_cart');
    },
      PTCloseWish() {
      this.$emit('home_close_wish');
    },},
  setup() {
    const brandData = ref({
      big_banners: [],
      products_with_variants: [],
    });
    const route = useRoute(); // Отримуємо доступ до маршруту

    const fetchBrandData = async (slug) => {
      try {
        const response = await instance.get(`/api/v1/shop/brands/${slug}/`);
        brandData.value = response.data;
      } catch (error) {
        console.error('Error fetching brand data:', error);
      }
    };

    onMounted(() => {
      const slug = route.params.slug || route.path.split('/').pop();
      fetchBrandData(slug);
    });
    return {
      brandData,
    };
  },
};
</script>

