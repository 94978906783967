<template>
  <div class="login-container">
    <form @submit.prevent="handleChangePassword" class="login-form">
      <h2>Зміна Паролю</h2>
      
      <!-- Поле Поточного Паролю -->
      <div class="form-group password-group">
        <div class="password-input-wrapper">
          <input 
            :type="showCurrentPassword ? 'text' : 'password'" 
            id="currentPassword" 
            v-model="currentPassword" 
            required 
            placeholder="Введіть поточний пароль" 
          />
          <span class="toggle-password" @click="toggleCurrentPassword">
            <svg v-if="!showCurrentPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8a21.18 21.18 0 0121.18-21.18"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
      </div>
      
      <!-- Поле Нового Паролю -->
      <div class="form-group password-group">
        <div class="password-input-wrapper">
          <input 
            :type="showNewPassword ? 'text' : 'password'" 
            id="newPassword" 
            v-model="newPassword" 
            required 
            placeholder="Введіть новий пароль" 
          />
          <span class="toggle-password" @click="toggleNewPassword">
            <svg v-if="!showNewPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8a21.18 21.18 0 0121.18-21.18"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
      </div>
      
      <!-- Поле Підтвердження Нового Паролю -->
      <div class="form-group password-group">
        <div class="password-input-wrapper">
          <input 
            :type="showConfirmNewPassword ? 'text' : 'password'" 
            id="confirmNewPassword" 
            v-model="confirmNewPassword" 
            required 
            placeholder="Підтвердіть новий пароль" 
          />
          <span class="toggle-password" @click="toggleConfirmNewPassword">
            <svg v-if="!showConfirmNewPassword" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off">
              <path d="M17.94 17.94A10.94 10.94 0 0112 20c-7 0-11-8-11-8a21.18 21.18 0 0121.18-21.18"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          </span>
        </div>
      </div>
      
      <!-- Кнопка Зміна Паролю -->
      <button type="submit" class="login-button">Змінити Пароль</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "ChangePassword",
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
    };
  },
  methods: {
    ...mapActions(['changePassword']), // Припускаємо, що у вашому Vuex є дія 'changePassword'
    
    // Методи для перемикання показу паролів
    toggleCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleConfirmNewPassword() {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    },
    
    // Метод для обробки форми зміни паролю
    handleChangePassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        alert('Нові паролі не збігаються');
        return;
      }
      
      this.changePassword({ 
        currentPassword: this.currentPassword, 
        newPassword: this.newPassword 
      })
        .then(() => {
          alert('Пароль успішно змінено');
          this.$router.push('/account'); // Перенаправлення до сторінки акаунта
        })
        .catch(error => {
          // Обробка помилок
          console.error('Помилка зміни паролю:', error);
          alert('Не вдалося змінити пароль. Перевірте ваші дані та спробуйте ще раз.');
        });
    }
  }
};
</script>

<style scoped>
.form-group {
  margin: 2rem 0;
}
.password-input-wrapper {
  position: relative;
}
input {
  padding: 0.75rem 0;
  text-align: center;
}
.toggle-password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
